import alloyAnalytics from '@telus/alloy-analytics'
import { ExpandCollapseProps, PanelProps } from '../../blocks/core/ExpandCollapse/ExpandCollapse'
import { ExpandCollapseMiniProps } from '../../blocks/core/ExpandCollapseMini/ExpandCollapseMini'
import {
  AccordionWithImageProps,
  AccordionItemProps,
} from '../../blocks/home_solutions/AccordionWithImage/AccordionWithImage'

type SysID = ExpandCollapseProps['sysID'] | ExpandCollapseMiniProps['sysID'] | AccordionWithImageProps['sysID']
type EntryTitle =
  | ExpandCollapseProps['entryTitle']
  | ExpandCollapseMiniProps['entryTitle']
  | AccordionWithImageProps['entryTitle']
type PanelID = PanelProps['panelId'] | ExpandCollapseMiniProps['collapseTitle'] | AccordionItemProps['heading']

type ExpandProps = { sysID: SysID; entryTitle: EntryTitle; panelID: PanelID }
type CollapseProps = { sysID: SysID; entryTitle: EntryTitle; panelID: PanelID }

const TOOL_TYPE = 'accordion'

const alloyEvents = {
  expand: ({ sysID, entryTitle, panelID }: ExpandProps) => {
    const toolSubmit = {
      name: `accordion_expand-${panelID}`,
      toolName: `accordion-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: sysID,
      toolSelections: `accordion_expand-${panelID}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
  collapse: ({ sysID, entryTitle, panelID }: CollapseProps) => {
    const toolSubmit = {
      name: `accordion_collapse-${panelID}`,
      toolName: `accordion-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: sysID,
      toolSelections: `accordion_collapse-${panelID}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
}

export default alloyEvents
