import { IExpandCollapseFields } from 'contentful-types'

import { BlockRenderer } from 'src/siteBuilder/renderer/contentful/BlockRenderer'
import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'

import ExpandCollapse, { ExpandCollapseProps } from './ExpandCollapse'

const ContentfulExpandCollapse = withContentful<IExpandCollapseFields, ExpandCollapseProps>(ExpandCollapse, {
  sysID: (props) => props.sys.id,
  entryTitle: (props) => props.fields.entryTitle,
  panels: (props) => {
    return props.fields.panels
      .filter((panel) => panel && panel.fields)
      .map((panel) => ({
        id: panel.sys.id,
        panelId: panel.fields.panelId,
        isExpanded: panel.fields.isExpanded,
        heading: panel.fields.heading,
        components: panel.fields.components,
        analyticsEvent: panel.fields.linkAnalyticsEvent,
        children: <BlockRenderer block={panel.fields.components} fieldPath={'.components'} />,
      }))
  },
  spacer: mapSpacer,
})

export default ContentfulExpandCollapse
