import React, { useEffect, useState } from 'react'
import { ExpandCollapse as AlliumExpandCollapse, StackView, Typography, Spacer } from '@telus-uds/components-web'

import { A11yTextRenderer } from '../../../utils/text/TextRenderer'
import useKoodoAnalyticsEvent from '../../../../hooks/useKoodoAnalyticsEvent'
import alloyEvents from '../../../utils/alloyAnalytics/accordion'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'

import type { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import type { SpacerProps } from '../Spacer/Spacer'

export type PanelProps = {
  id: string
  panelId: string
  isExpanded: boolean
  heading: string
  components?: any[]
  children: React.ReactNode
  analyticsEvent?: ContentfulAnalyticsEventType
}

export type ExpandCollapseProps = {
  sysID: string
  entryTitle: string
  panels: PanelProps[]
  hasTopDivider?: boolean
  hasBottomDivider?: boolean
  hasCompactSpacing?: boolean
  spacer?: SpacerProps
}

const renderHeading = (heading) => {
  return (
    <Typography variant={{ size: 'h4' }} dataSet={{ 'sb-field-path': '.heading' }} data-testid="headings">
      <A11yTextRenderer>{heading}</A11yTextRenderer>
    </Typography>
  )
}

const ExpandCollapse = ({
  sysID,
  entryTitle,
  panels,
  hasTopDivider = true,
  hasBottomDivider = true,
  hasCompactSpacing = false,
  spacer = undefined,
  ...rest
}: ExpandCollapseProps) => {
  const renderSpacing = hasCompactSpacing ? 0 : 2
  const fieldPath = rest['data-sb-field-path'] as string

  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = useState(false)
  const [analytics, setAnalytics] = useState<ContentfulAnalyticsEventType>(null)
  const [openPanels, setOpenPanels] = React.useState(
    panels.reduce((acc: string[], panel) => {
      if (panel.isExpanded) acc.push(panel.panelId)

      return acc
    }, [])
  )

  useKoodoAnalyticsEvent(analytics, isAnalyticsTriggered)

  useEffect(() => setIsAnalyticsTriggered(true), [analytics])

  const handleOnChange = (activePanels: string[]) => {
    // remove the hash from the url

    // Using location.hash
    // TODO: this adds a new history entry to the browser, back button will take to previous url
    //location.hash = ''

    // Using history.replaceState
    // info: this does NOT adds history in the browser, not count as a changed.
    // history.replaceState(null, null, window.location.pathname + window.location.search)

    // set active panels
    setOpenPanels(activePanels)

    // istanbul ignore next: analytics is not triggered in tests
    panels.forEach((panel) => {
      if (activePanels.includes(panel.panelId) && panel.analyticsEvent) setAnalytics(panel.analyticsEvent)
    })
  }

  useEffect(() => {
    // istanbul ignore next: this is a browser api
    const handleHashChange = () => {
      // get the hash from the url without the #
      const hash = window.location.hash.substring(1)

      // find the panel that matches the hash
      const panel = panels.find((panel) => panel.panelId === hash)

      // set the panel to be open
      if (panel) setOpenPanels((prevOpenPanels) => [...prevOpenPanels, panel.panelId])
    }

    window.addEventListener('hashchange', handleHashChange)

    return () => window.removeEventListener('hashchange', handleHashChange)
  }, [panels])

  const renderPanels = (expandProps) =>
    panels?.map((panel, index) => {
      const hasContent = panel.components?.length > 0
      const heading = renderHeading(panel.heading)
      return (
        <div key={index} data-sb-field-path={`.${index}`}>
          {
            <AlliumExpandCollapse.Panel
              key={panel.id}
              {...expandProps}
              panelId={panel.panelId}
              control={heading}
              onPress={() => {
                const accordionEvent = !openPanels.includes(panel.panelId) ? alloyEvents.expand : alloyEvents.collapse

                accordionEvent({ sysID, entryTitle, panelID: panel.panelId })
              }}
              content={!hasContent}
            >
              {hasContent ? panel.children : heading}
            </AlliumExpandCollapse.Panel>
          }
        </div>
      )
    })

  return (
    <div data-sb-field-path={fieldPath}>
      {renderSpacer(spacer)}
      <AlliumExpandCollapse
        dataSet={{ 'sb-field-path': `.expandCollapse` }}
        open={openPanels}
        onChange={handleOnChange}
      >
        {(expandProps) => (
          <StackView
            divider
            space={renderSpacing}
            dataSet={{ 'sb-field-path': `.panels` }}
            testID="expand-collapse-content-spacer"
          >
            {hasTopDivider && <Spacer space={0} key={'topDividerExpandCollapse'} />}
            {renderPanels(expandProps)}
            {hasBottomDivider && <Spacer space={0} key={'bottomDividerExpandCollapse'} />}
          </StackView>
        )}
      </AlliumExpandCollapse>
    </div>
  )
}

export default ExpandCollapse
